import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import media from "styled-media-query"

const Footer = ({ appStoreImg }) => {
  const img = useStaticQuery(graphql`
    query {
      appStore: file(relativePath: { eq: "appstoreLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <AppStoreImg to="https://apps.apple.com/us/app/show-stopwatch/id1211287819">
        <Img fluid={img.appStore.childImageSharp.fluid} className="img" />
      </AppStoreImg>
      <Foot>Show StopWatch © {new Date().getFullYear()} Jack Lord</Foot>
    </>
  )
}

export default Footer

const Foot = styled.div`
  margin: 1em;
  padding-bottom: 1em;
  clear: both;
  width: 100%;
  position: relative;
  text-align: center;
  color: #8d8d8d;
  /* background-color: blue; */
`
const AppStoreImg = styled(Link)`
  display: none;
  margin: 1em;
  padding: 0;
  .img {
    width: 30%;
  }

  ${media.lessThan("medium")`
    /* margin-right: 5px; */
    display:flex;
    justify-content:center
    `};
`
