/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import NavBar from "../nav/nav-bar"
import Footer from "../footer"
import "./layoutcss.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <NavBar siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Main = styled.main`
  transition: 1s ease-in-out;
  /* background-color: #5e5e5e; */
  position: relative;
  min-height: calc(100vh - 30px);
  padding-top: 60px;
  ${media.greaterThan("medium")`
    margin-top: 0px;
    padding-top: 90px;
  `};
`
