import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import Item from "./nav-item"
import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

const NavBar = ({ siteTitle }) => {
  const logo = useStaticQuery(graphql`
    query {
      appIcon: file(relativePath: { eq: "logo-nobackground.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appStore: file(relativePath: { eq: "appstoreLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  // const [show, setShow] = useState(true)
  // const [scrollPosition, setScrollPosition] = useState(0)
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)

  const closeMenu = () => setMobileMenuIsOpen(false)

  return (
    <Transition>
      <Desktop>
        <Header>
          <AppIcon to="/">
            <Img fluid={logo.appIcon.childImageSharp.fluid} className="logo" />
          </AppIcon>
          <Title to="/">Show StopWatch</Title>
          <List>
            <Item to="/" text="Home" />
            <Item to="/about" text="About" />
            <Item to="/privacy" text="Privacy" />
            <Item to="/contact" text="Contact" />
          </List>

          <AppStoreLogo to="https://apps.apple.com/us/app/show-stopwatch/id1211287819">
            <Img
              fluid={logo.appStore.childImageSharp.fluid}
              className="appStoreLogo"
            />
          </AppStoreLogo>
        </Header>
      </Desktop>
      <Mobile>
        <Header>
          <AppIcon to="/">
            <Img fluid={logo.appIcon.childImageSharp.fluid} className="logo" />
          </AppIcon>
          <Title to="/">Show StopWatch</Title>
          <MenuWrapper>
            <Menu
              isOpen={mobileMenuIsOpen}
              onStateChange={({ isOpen }) => setMobileMenuIsOpen(isOpen)}
              width="100%"
              right
            >
              <Item to="/" text="Home" closeMenu={closeMenu} />
              <Item to="/about" text="About" closeMenu={closeMenu} />
              <Item to="/privacy" text="Privacy" closeMenu={closeMenu} />
              <Item to="/contact" text="Contact" closeMenu={closeMenu} />
            </Menu>
          </MenuWrapper>
        </Header>
      </Mobile>
    </Transition>
  )
}

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar

const Desktop = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: block;
  `}
  background-color:green;
`

const Title = styled(Link)`
  /* padding-top: 15px; */
  padding-right: 2rem;
  text-decoration: none;
  align-items: center;
  color: white;
  font-weight: lighter;
  font-size: 2.5em;

  ${media.lessThan("medium")`
  font-size:1.75em;
  `}
`

const List = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  /* border: 1px solid red; */
  margin: 0 0 0 auto;
  padding: 0;
  padding-right: 2em;
  ${media.greaterThan("medium")`
    margin-top: 2px;
  `};
  ${media.greaterThan("large")`
     margin-top: 3px;
  `};
`

const AppIcon = styled(Link)`
  /* width: 5%; */
  margin-right: 1em;
  width: 30px;
  ${media.lessThan("medium")`
    margin-left: 0.5em;
    `};

  ${media.greaterThan("medium")`
    width: 50px;
  `};
`

const Header = styled.header`
  z-index: 999;
  position: fixed;
  height: 60px;
  width: 100%;
  ${p => p.scrollPos < -100 && `background-color: ${p.theme.colors.primary}`};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color:#2f034e ;
  /* background-color: #3d3d3d; */

  ${media.greaterThan("medium")`
    height: 90px;
    padding-left: 10px;
  `};
  ${media.greaterThan("large")`
     padding-left: 30px;
  `};
`
const AppStoreLogo = styled(Link)`
  width: 15%;
  margin-right: 1em;
`

const Transition = styled.div`
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`

const Mobile = styled.div`
  display: block;
  ${media.greaterThan("medium")`
    display: none;
    `}
`

const MenuWrapper = styled.div`
  .bm-burger-button {
    position: fixed;
    right: 0;
    width: 25px;
    height: 20px;
    margin-top: -13px;
    margin-right: 24px;
  }

  .bm-burger-bars,
  .bm-burger-bars-hover {
    background: white;
  }

  .bm-cross {
    background: white;
  }

  .bm-menu {
    width: 200px;
    float: right;
    background: black;
    padding: 2.5em 1.5em 0;
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
  }

  .bm-menu-wrap {
    margin-top: -30px;
  }
`
